import React, { FunctionComponent } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { errorsData } from "./errorsData";
import { TableSortLabel } from "@material-ui/core";

type Order = "asc" | "desc";

interface Column {
  id: "code" | "time" | "description" | "severity";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  sort: (a: Alarm, b: Alarm) => number;
}

const columns: Column[] = [
  {
    id: "code",
    label: "Code",
    minWidth: 80,
    sort: (a: Alarm, b: Alarm) => a.code.localeCompare(b.code),
  },
  {
    id: "time",
    label: "Time",
    minWidth: 80,
    sort: (a: Alarm, b: Alarm) =>
      new Date(a.time).getTime() - new Date(b.time).getTime(),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
    sort: (a: Alarm, b: Alarm) => a.description.localeCompare(b.description),
  },
  {
    id: "severity",
    label: "Severity",
    minWidth: 80,
    align: "right",
    sort: (a: Alarm, b: Alarm) => a.severity.localeCompare(b.severity),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

interface Alarm {
  code: string;
  time: string;
  description: string;
  severity: string;
}

const rows = errorsData;

// @ts-ignore
export interface ErrorLogsProps {}

export const ErrorLogs: FunctionComponent<ErrorLogsProps> = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<Column["id"]>("time");
  const sortRoutine = columns.find((c) => c.id === orderBy)?.sort;
  if (!sortRoutine) {
    throw new Error("sortRoutine not found");
  }

  const handleRequestSort = (property: Column["id"]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort((a: Alarm, b: Alarm) =>
                order === "asc" ? sortRoutine(a, b) : -sortRoutine(a, b)
              )
              .map((row, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column, idx) => {
                    const value = row[column.id];
                    const v =
                      column.format && typeof value === "number"
                        ? column.format(value)
                        : value;
                    return (
                      <TableCell
                        key={`${column.id}-${i}-${idx}`}
                        align={column.align}
                      >
                        {v}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ErrorLogs;
