import { Box, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import React, { FunctionComponent } from "react";
import AggregationCard from "../aggregation-card/AggregationCard";
import ErrorLogs from "../error-logs/ErrorLogs";
import { errorAggregation } from "../error-logs/errorsData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    text: {
      padding: theme.spacing(0, 0, 2),
    },
  })
);

const text = {
  title: "Alarms",
  CriticalAlarmsCount: "Critical Alarms",
  InfoAlarmsCount: "Info Alarms",
  WarningAlarmsCount: "Warning Alarms",
};

// @ts-ignore
export interface ErrorScreenProps {}

export const ErrorScreen: FunctionComponent<ErrorScreenProps> = () => {
  const classes = useStyles();

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid
        container
        spacing={2}
        justify={"flex-start"}
        style={{ paddingBottom: 20 }}
      >
        <Grid item xs={12}>
          <Box mb={1}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item>
                <ErrorIcon />
              </Grid>
              <Grid item>
                <Typography color="secondary" variant="h5">
                  {text.title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3} md={2}>
          <AggregationCard
            title={text.CriticalAlarmsCount}
            color={"red"}
            aggregation={errorAggregation.CriticalAlarmsCount}
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <AggregationCard
            title={text.WarningAlarmsCount}
            color={"orange"}
            aggregation={errorAggregation.WarningAlarmsCount}
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <AggregationCard
            title={text.InfoAlarmsCount}
            color={"#00BCEB"}
            aggregation={errorAggregation.InfoAlarmsCount}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ErrorLogs />
      </Grid>
    </Box>
  );
};

export default ErrorScreen;
